import { Locale } from '@fxtr/i18n';

interface AmazonAdsSettings {
  readonly locale: Locale;
  readonly value: number;
  readonly bookingId: string | number;
  readonly item: string;
}

/**
 * Extracted in this function to be able to use it on Page Router too
 */
export const makeAmazonAdsPageViewScriptContents = (
  id: string,
  trackEventName?: string,
  options?: AmazonAdsSettings
): string =>
  `!function(w,d,s,t,a){if(w.amzn)return;w.amzn=a=function(){w.amzn.q.push([arguments,(new Date).getTime()])};a.q=[];a.version="0.0";s=d.createElement("script");s.src="https://c.amazon-adsystem.com/aat/amzn.js";s.id="amzn-pixel";s.async=true;t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t)}(window,document);
  amzn("setRegion", "EU");
  amzn("addTag", "${id}");
  amzn("trackEvent", "PageView");
  ${
    trackEventName
      ? `amzn("trackEvent", "${trackEventName}"${
          trackEventName === 'Purchase' && options
            ? `{
              currencyCode: "${options.locale === Locale.FR_FR ? 'EUR' : 'GBP'}",
              value: ${options.value},
              clientDedupeId: "${options.bookingId}",
              name: "${options.item}",
            }`
            : ''
        });`
      : ''
  }`;
